import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid' 
import Logo from '@Elements/logo'

const Section = styled.section`${styles}`
const Header = () => {
  return (
    <Section>
      <Container>
        <Row>
          <Col style={{ height: '80px', display: 'flex', alignItems: 'center' }}>
            <Logo link="/" />
          </Col>
        </Row>
      </Container>
    </Section>
  )
} 


export default Header
