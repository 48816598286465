import { css } from 'styled-components'

export default css`
  width: 100%;
  height: 80px;
  background: linear-gradient(to right, rgb(0, 180, 219), rgb(0, 131, 176));
  background: linear-gradient(60deg,#562db5,#19afc9);
  display: flex;
  align-items: center;
`
