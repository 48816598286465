import { css } from 'styled-components'

export default css`
  h1 {
    font-size: 6rem;
    text-align: right;
    color: #4c95c2;
  }
  h2 {
    font-size: 3rem;
    color: #249da4;
    text-align: right;
  }
`